import { lastPageAtom } from "../../../atoms/lastPageAtom";
import { globalSearchPopupAtom } from "../../../atoms/popupAtoms";
import useUser from "../../../hooks/useUser";
import getTranslation from "../../../libs/getTranslations";
import NavigationSearchBar from "../../elements/NavigationSearchBar";
import LogoBlack from "../../elements/brand/LogoBlack";
import MiniButton from "../../elements/buttons/MiniButton";
import PrimaryGreenButton from "../../elements/buttons/PrimaryGreenButton";
import TextButton from "../../elements/buttons/TextButton";
import RegularContainer from "../../layouts/RegularContainer";
import LanguageSwicther from "./LanguageSwitcher";
import NavigationSidebar from "./NavigationSidebar";
import { DotGridFill, Search } from "akar-icons";
import { atom, useAtom } from "jotai";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

const ProfileButton = dynamic(() => import("./ProfileButton"), { ssr: false });

export const sidebarAtom = atom(false);

const Navigation = (props) => {
  const router = useRouter();
  const { locale, locales, asPath, query } = router;
  const [, setSearch] = useAtom(globalSearchPopupAtom);
  const [, setLastPage] = useAtom(lastPageAtom);

  const { loading, authenticated, user, unauthenticated } = useUser();

  const localeLinks = props.localeLinks || {
    en: "/en",
    ru: "/ru",
    es: "/es",
    tr: "/",
  };
  const t = getTranslation(locale);

  const [sidebarOpen, setSidebarOpen] = useAtom(sidebarAtom);

  const sidebarHandler = () => {
    setSidebarOpen((sidebarOpen) => !sidebarOpen);
  };

  // Set Last Page Atom
  useEffect(() => {
    if (asPath !== "/") {
      setLastPage({
        asPath,
        source: query?.source || "",
        medium: query?.medium || "",
      });
    }
  }, [asPath, query.medium, query.source, setLastPage]);

  return (
    <>
      <div className="sticky top-0 left-0 z-40 w-full px-6 py-4 bg-white border-b bg-opacity-90 backdrop-blur-sm border-b-primaryLight">
        <RegularContainer>
          <nav className="flex items-center justify-between w-full">
            <div className="mr-4">
              <Link prefetch={false} href="/">
                <LogoBlack />
              </Link>
            </div>
            <NavigationSearchBar
              placeholder={
                locale === "tr"
                  ? null
                  : t.navigation.navigationSearchPlaceholder
              }
              button={
                locale === "tr" ? null : t.navigation.navigationSearchButton
              }
              setState={setSearch}
            />
            <div className="flex items-center gap-6">
              {loading && (
                <div className="hidden gap-3 md:flex">
                  <div className="text-gray-200 bg-gray-200 rounded-md  whitespace-nowrap animate-pulse">
                    Giriş Yap
                  </div>
                  <div className="text-gray-200 bg-gray-200 rounded-md  whitespace-nowrap animate-pulse">
                    Üye Ol
                  </div>
                </div>
              )}
              {authenticated && <ProfileButton user={user} />}
              {unauthenticated && (
                <div className="items-center hidden gap-6 md:flex">
                  <TextButton
                    href={locale === "tr" ? "/giris" : t.auth.loginLink}
                    locale={locale}
                  >
                    {locale === "tr" ? "Giriş Yap" : t.auth.login}
                  </TextButton>
                  <PrimaryGreenButton
                    type="link"
                    size="small"
                    href={locale === "tr" ? "/kayit" : t.auth.signupLink}
                  >
                    {locale === "tr" ? "Üye Ol" : t.auth.signup}
                  </PrimaryGreenButton>
                </div>
              )}
              {/* Language Switcher */}
              <div className="hidden md:block">
                <LanguageSwicther
                  locales={locales}
                  active={locale}
                  localeLinks={localeLinks}
                />
              </div>
              {/* Search Button */}
              <div className="lg:hidden">
                <MiniButton
                  type="button"
                  onClick={() => {
                    setSearch({ show: true });
                  }}
                >
                  <Search size={20} />
                </MiniButton>
              </div>
              {/* Hamburger Menu */}
              <MiniButton onClick={sidebarHandler}>
                <DotGridFill size={20} />
              </MiniButton>
            </div>
          </nav>
        </RegularContainer>
      </div>
      <NavigationSidebar
        locales={locales}
        locale={locale}
        localeLinks={localeLinks}
        handler={sidebarHandler}
        show={sidebarOpen}
        user={user}
      />
    </>
  );
};

export default Navigation;
