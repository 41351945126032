import getFAQSchema from "../libs/getFAQSchema";
import getTranslation from "../libs/getTranslations";
import Head from "next/head";
import { useRouter } from "next/router";

// PARAMS

// const localeLinks = {
//   en: "/",
//   ru: "/",
//   es: "/",
//   tr: "/",
// };

const useHeadComponent = ({
  title,
  translationKey,
  description,
  alternateList,
  dynamic = false,
  noindex = false,
  noBrand = false,
  ogImage,
  ogType,
  schemaMarkup = null,
  faq = null,
}) => {
  const alternateListKeys = alternateList ? Object.keys(alternateList) : false;
  const { locale, asPath } = useRouter();
  const t = getTranslation(locale);
  const suffix = noBrand ? "" : " | agrowy";
  const currentUrl =
    process.env.NEXT_PUBLIC_URL +
    (locale !== "tr" ? "/" + locale : "") +
    asPath;

  return (
    <Head>
      <title>
        {dynamic
          ? title + suffix
          : locale === "tr"
          ? title + suffix
          : t.pageTitles[translationKey] + suffix}
      </title>
      <meta
        name="description"
        content={
          dynamic
            ? description
            : locale === "tr"
            ? description
            : t.pageDescriptions[translationKey]
        }
      />
      <link
        rel="canonical"
        href={
          process.env.NEXT_PUBLIC_URL +
          (locale != "tr" ? "/" + locale : "") +
          asPath.split("?")[0]
        }
      />

      {/* FAQ Questions */}
      {faq && getFAQSchema(faq)}

      {/* Open Graph Tags */}
      <meta
        name="facebook-domain-verification"
        content="6xu35vufnpj55esftsxuh7hx3tgqze"
      />
      <meta
        property="og:url"
        content={
          process.env.NEXT_PUBLIC_URL +
          (locale != "tr" ? "/" + locale : "") +
          asPath.split("?")[0]
        }
      />
      <meta property="og:type" content={ogType || "website"} />
      <meta
        content={
          (dynamic
            ? title
            : locale === "tr"
            ? title
            : t.pageTitles[translationKey]) + (!noBrand ? " | agrowy" : "")
        }
        property="og:title"
      />
      <meta
        content={
          dynamic
            ? description
            : locale === "tr"
            ? description
            : t.pageDescriptions[translationKey]
        }
        property="og:description"
      />
      <meta
        content={
          process.env.NEXT_PUBLIC_IMAGE_URL +
          (ogImage || "2f24c8a2-d8a0-4dd8-893c-3d57ee55579e")
        }
        property="og:image"
      />
      <meta
        content={
          (dynamic
            ? title
            : locale === "tr"
            ? title
            : t.pageTitles[translationKey]) + (!noBrand ? " | agrowy" : "")
        }
        property="twitter:title"
      />
      <meta
        content={
          dynamic
            ? description
            : locale === "tr"
            ? description
            : t.pageDescriptions[translationKey]
        }
        property="twitter:description"
      />
      <meta
        content={
          process.env.NEXT_PUBLIC_IMAGE_URL +
          (ogImage || "2f24c8a2-d8a0-4dd8-893c-3d57ee55579e")
        }
        property="twitter:image"
      />
      <meta property="og:type" content="website" />
      {/* Schema Markup */}
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      {/* No INDEX TAG For Not Listed Pages */}
      {noindex ? (
        <meta name="robots" content="noindex,nofollow,noimageindex" />
      ) : null}
        {alternateListKeys &&
          alternateListKeys.map((key) => (
            <link
              key={key}
              rel="alternate"
              href={process.env.NEXT_PUBLIC_URL + alternateList[key]}
              hrefLang={key === "tr" ? "x-default" : key}
            />
          ))}
        <link rel="alternate" href={currentUrl} hrefLang={locale} />
    </Head>
  );
};

export default useHeadComponent;
